import type { Document } from '~/types/document'

export function getDocumentFromFolders(
  folderId: number,
): Document[] {
  const { documents } = useDocuments()
  const folderDocuments: Document[] = []

  documents?.forEach((document) => {
    if (document.folder_id === folderId) {
      folderDocuments.push(document)
    }
  })

  return folderDocuments
}
