import type { FolderDTO, Folder } from '~/types/folder'
import type { Document } from '~/types/document'
export const useFolders = defineStore('folders', () => {
  const baseApiURL = useRuntimeConfig().public.apiUrl
  const folders: Ref<Folder[]> = useState(
    'folders:active',
    () => [],
  )
  const loading: Ref<boolean> = useState(
    'folders:loading',
    () => false,
  )
  const folder = ref<Folder | undefined>(undefined)
  const { company } = useCompany()

  const fetchFolderByMachineIdAndUuid = async (
    machineId: number,
    uuid: string,
  ) => {
    loading.value = true

    try {
      folder.value = await $fetch(
        `${baseApiURL}/api/folders/machine/${machineId}/uuid/${uuid}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${useCookie('auteba').value}`,
          },
        },
      )
      loading.value = false
    } catch (e) {
      console.error(e)
    } finally {
      loading.value = false
    }
  }

  async function createFolder(folderData: FolderDTO) {
    try {
      const newFolder = await $fetch<any>(
        `${baseApiURL}/api/folders/`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${useCookie('auteba').value}`,
          },
          body: {
            ...folderData,
          },
        },
      )

      folders.value.push(newFolder)

      return newFolder
    } catch (e: any) {
      console.error(e)
      throw e
    }
  }

  async function getFoldersByMachineId(
    machineId: number,
  ): Promise<Folder[]> {
    loading.value = true

    try {
      const machineFolders: Folder[] =
        getFolderFromMachine(machineId)

      if (machineFolders.length > 0) {
        return machineFolders
      }

      const newFolders: Folder[] = await $fetch<Folder[]>(
        `${baseApiURL}/api/folders/machine/${machineId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${useCookie('auteba').value}`,
          },
        },
      )

      newFolders.forEach((folder) => {
        folders.value?.push(folder)
      })

      return newFolders
    } catch (e) {
      console.error(e)
      throw e
    } finally {
      loading.value = false
    }
  }

  async function getFoldersByCompanyId(companyId: number) {
    loading.value = true

    try {
      folders.value = await $fetch(
        `${baseApiURL}/api/folders/company/${companyId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${useCookie('auteba').value}`,
          },
        },
      )

      return folders.value
    } catch (e) {
      console.error(e)
    } finally {
      loading.value = false
    }
  }

  async function getFolderByUuid(uuid: string) {
    loading.value = true

    try {
      const respFolder = await $fetch(
        `${baseApiURL}/api/folders/uuid/${uuid}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${useCookie('auteba').value}`,
          },
        },
      )

      return respFolder
    } catch (e) {
      console.error(e)
    } finally {
      loading.value = false
    }
  }

  async function getFolderQr(
    folder_id: number,
  ): Promise<Folder | undefined> {
    try {
      const folderWithQr = await $fetch<Folder>(
        `${baseApiURL}/api/folders/${folder_id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${useCookie('auteba').value}`,
          },
        },
      )

      const index = folders.value.findIndex(
        (f) => f.id === folder_id,
      )

      if (index !== -1) {
        folders.value[index] = folderWithQr
      }

      return folderWithQr
    } catch (e) {
      console.error(e)
    } finally {
      loading.value = false
    }
  }

  function deleteFolder(folder_id: number) {
    try {
      $fetch(`${baseApiURL}/api/folders/${folder_id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${useCookie('auteba').value}`,
        },
      })

      useDocuments().clearDocuments(folder_id)

      const index = folders.value.findIndex(
        (f) => f.id === folder_id,
      )

      if (index !== -1) {
        folders.value.splice(index, 1)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return {
    folders,
    folder,
    loading,

    createFolder,

    getFoldersByMachineId,
    getFoldersByCompanyId,
    getFolderQr,
    getFolderByUuid,
    fetchFolderByMachineIdAndUuid,

    deleteFolder,
  }
})
