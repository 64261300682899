import type {
  DocumentDTO,
  Document,
} from '~/types/document'

export const useDocuments = defineStore('documents', () => {
  const baseApiURL = useRuntimeConfig().public.apiUrl
  const documents: Ref<Document[]> = useState(
    'documents:active',
    () => [],
  )
  const loading: Ref<boolean> = useState(
    'documents:loading',
    () => false,
  )
  const { company } = useCompany()

  async function uploadDocuments(
    documentsData: DocumentDTO[],
    machine_id: number,
    folder_id: number,
  ) {
    try {
      loading.value = true

      const newDocuments = await $fetch<Document[]>(
        `${useRuntimeConfig().public.apiUrl}/api/documents/`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${useCookie('auteba').value}`,
          },
          body: {
            documents: documentsData,
            company_id: company?.id,
            machine_id,
            folder_id,
          },
        },
      )

      newDocuments.forEach((newDoc) => {
        const index = documents.value.findIndex(
          (doc) => doc.id === newDoc.id,
        )
        if (index !== -1) {
          documents.value[index] = newDoc
        } else {
          documents.value.push(newDoc)
        }
      })

      return newDocuments
    } catch (e) {
      console.error(e)
    } finally {
      loading.value = false
    }
  }

  async function getDocumentsByFolderId(folderId: number) {
    try {
      const docs = getDocumentFromFolders(folderId)

      if (docs.length > 0) {
        return docs
      }

      const newDocuments: Document[] = await $fetch<
        Document[]
      >(`${baseApiURL}/api/documents/folder/${folderId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${useCookie('auteba').value}`,
        },
      })

      newDocuments.forEach((doc) => {
        documents.value?.push(doc)
      })

      return newDocuments
    } catch (e) {
      console.error(e)
    }
  }

  function deleteDocument(documentId: number) {
    try {
      $fetch(`${baseApiURL}/api/documents/${documentId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${useCookie('auteba').value}`,
        },
      })

      const index = documents.value.findIndex(
        (doc) => doc.id === documentId,
      )
      if (index !== -1) {
        documents.value.splice(index, 1)
      }
    } catch (e) {
      console.error(e)
    }
  }

  function clearDocuments(folder_id: number) {
    documents.value = documents.value.filter(
      (doc) => doc.folder_id !== folder_id,
    )
  }

  return {
    documents,
    loading,

    getDocumentsByFolderId,

    uploadDocuments,

    deleteDocument,
    clearDocuments,
  }
})
