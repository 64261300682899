import type { Folder } from '~/types/folder'

export function getFolderFromMachine(
  machineId: number,
): Folder[] {
  const { folders } = useFolders()
  const foldersFromMachine: Folder[] = []

  folders?.forEach((folder) => {
    if (folder.machine_id === machineId) {
      foldersFromMachine.push(folder)
    }
  })

  return foldersFromMachine
}
